<template>
  <div>
    <LiefengContent  @backClick="backClick" :isBack='true'>
      <template v-slot:title  >授权系统</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <Button
              type="primary"
              @click="inas"
          >授权
          </Button>
        </Form>
      </template>
      <template v-slot:contentArea>
        <LiefengTable
            :talbeColumns="talbeColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="changeTableSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
        <LiefengModal
            title="授权"
            :value="inasStatus"
            @input="changeInasStatus"
            width='600px'
            height='600px'

          >
          <template v-slot:contentarea>
            <!-- <Checkbox @on-change="(val) => changeCheck(val,item)" v-model="item.select"  v-for="(item,index) in inasList" :key="index" :label="item.label">{{item.value}}</Checkbox> -->
            <Tree :data="inasList" show-checkbox></Tree>
          </template>
          <template v-slot:toolsbar>
              <Button type="primary" @click="saveInas">保存</Button>
          </template>
        </LiefengModal>
        <LiefengModal
          title="授权菜单"
          :value="menuStatus"
          @input="changeMenuStatus"
          :fullscreen="false"
          width="500px"
          height="600px"
          >
          <template v-slot:contentarea>
            <Tree 
              :data="menuTree" 
              show-checkbox
            >
            </Tree>
          </template>
          <template v-slot:toolsbar>
             <Button type="primary" style="margin-right:10px" @click="saveMenu">保存</Button>
             <Button type="info" @click="changeMenuStatus(false)">取消</Button>
          </template>
        </LiefengModal>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";

export default {
  components: {LiefengContent, LiefengTable, LiefengModal},
  data() {
    return {
      //租户id，有可能会传
      tenantId: '',
      //分页参数
      talbeColumns: [
          {
            type: "selection",
            width: 60,
            align: "center"
          },
          {
            title: "系统名称",
            minWidth: 200,
            align: "center",
            key:'appName'
            
          },
          {
            title: "系统编码",
            minWidth: 200,
            align: "center",
            key:'appCode'
          },
          {
            title: "域名",
            // key: "oemName",
            minWidth: 200,
            align: "center",
            render:(h,params) => {
                return h(
                    'div',{},params.row.extra.IndexParam.systemIndex?params.row.extra.IndexParam.systemIndex:''
                )
            }
          },
          {
            title: "授权开始时间",
            key: "appName",
            minWidth: 200,
            align: "center",
            render:( h,params) => {
              return h(
                'div',{},params.row.gmtCreate?this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd'):''
              )
            }
          },
          {
            title: "过期时间",
            key: "remark",
            minWidth: 200,
            align: "center",
            render:( h,params) => {
              return h(
                'div',{},params.row.gmtCreate?this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd'):''
              )
            }
          },
          
          {
            title: "状态",
            key: "status",
            minWidth: 200,
            align: "center",
             render: (h, params) => {
              return h(
                'i-Switch',
                {
                  props: {
                    size: 'large',
                    value: params.row.sysStatus == 1
                  },
                  scopedSlots: {
                    open: () => h('span', '启用'),
                    close: () => h('span', '停用')
                  },
                  on: {
                    'on-change': () => {
                      this.changeStatus(params.row)
                    }
                  }
                }
              )
            }
            // render:(h,params) =>{
            //   return h('div',{},params.row.status == 1 ?'正常':'禁用' )
            // }
          },
          {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 180,
            render: (h, params) => {
              return h(
                  "div",
                  {
                    style: {
                      textAlign: "center"
                    }
                  },
                  [
                    h(
                        "Dropdown",
                        {
                          props: {
                            transfer: true
                          }
                        },
                        [
                          h(
                              "Button",
                              {
                                props: {
                                  type: "success",
                                  size: "small",
                                  icon: "ios-arrow-down"
                                }
                              },
                              "操作"
                          ),
                          h(
                              "DropdownMenu",
                              {
                                slot: "list"
                              },
                              [
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.openMenuTree(params.row)
                                          
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "授权菜单"
                                ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          // this.getSonSystem( ``)
                                          this.$router.push(`/authCloudSonSystem?systemId=${params.row.systemId}&tenantId=${this.$route.query.tenantId}&tenantSystemId=${params.row.tenantSystemId}`)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "定义子系统"
                                ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                            //   this.deleteRow(params.row.tenantSystemId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "删除"
                                ),
                              ]
                          )
                        ]
                    )
                  ]
              );
            }
          },

      ],
      tableData: [],
      loading: false,
      page: 1,
      pageSize: 20,
      total: 0,
      currentPage: 0,

      // 授权
      inasStatus:false,
      inasList:[],
      warrantList:[],

      // 授权菜单
      menuStatus:false,
      menuTree:[],
      tenantSystemModuleList:[],
      tenantSystemId:''
      
      
    }
  },
  async created() {
    console.log(parent.vue.loginInfo.userinfo)
    await this.getWarrantList()
    await this.getInasList(this.$route.query.tenantId)
    this.getList(this.$route.query.tenantId)
    
    
  },
  methods: {
    // 顶部返回
    backClick(){
      this.$router.push('/authCloudTenant')
    },
    // 选框
    tableSelect(){

    },
    // 分页
    changeTableSize(val) {
        this.page = val.page
        this.pageSize = val.pageSize
        this.getList()
    },
    // 获取列表
    getList(tenantId) {
      this.loading = true
      this.$get('/auth/api/auth/pc/tenantSystem/selectByPage', {
        page: this.page,
        pageSize: this.pageSize,
        tenantId: tenantId,
        parentId:0
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.dataList.map( e=> {
            if(e.extra){
              let obj = JSON.parse(e.extra.IndexParam) 
              e.extra.IndexParam = obj
              return{
                ...e,
              }
            }else{
              let obj = {IndexParam:{systemIndex:''}}
              e.extra = obj
              return {
                ...e
              }
            }
          })
          this.total = res.maxCount
          console.log(this.tableData)
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    // 更改系统状态
    changeStatus(data){
      this.$post('/auth/api/auth/pc/tenantSystem/updateStatus',{
        tenantSystemId:data.tenantSystemId,
        status:data.sysStatus == 1?2:1
      }).then(res => {
        if(res.code == 200){
          this.$Message.success({
            content: '操作成功',
            background: true
          })
          this.getList(this.$route.query.tenantId)
        }else {
          this.$Message.error({
            content: '操作失败',
            background: true
          })
          this.getList(this.$route.query.tenantId)
        }
        
      })
    },
    // 获取授权系统树
    async getInasList(tenantId) {
      await this.$get('/auth/api/auth/pc/system/selectSystemTree', {

      }).then(res => {
        if (res.code == 200) {
          this.inasList = res.dataList
          this.handleinasTree(this.inasList)
        } else {
          this.$Message.error({
            content: '获取授权失败',
            background: true
          })
          return
        }
      })
    },
     handleinasTree(list){
      list.forEach( (e,i) => {
        list[i] = {
          title : e.label,
          expand : true,
          children: e.children,
          value:e.value,
        }
        if(this.warrantList.includes(e.value)){
          list[i].checked = true
        }else {
          list[i].checked = false
        }
        if (list[i].children) {
          this.handleinasTree(list[i].children);
        } else {
          delete(list[i]['expand'])
          delete(list[i]['children'])
        }
      } )
    },
    // 获取已授权系统列表
    async getWarrantList(){
      await this.$get('/auth/api/auth/pc/tenantSystem/queryTenantSystemListByTenantId',{
        tenantId:this.$route.query.tenantId
      }).then( res => {
        if(res.code == 200){
          // this.warrantList = res.dataList
          this.warrantList = res.dataList.map( e => e.systemId)
          console.log(this.warrantList)
        }
      })
    },
    
    // 点击授权
    inas(){
      this.inasStatus = true
      this.getWarrantList()
      this.getInasList(this.$route.query.tenantId)
    },
    // 授权框状态
    changeInasStatus(val){
      this.inasStatus = val
    },
    // 授权选择
    // changeCheck(item,val){
    //   if(val.value == '全部' && item == true ){
    //     this.inasList.map( e => {
    //       e.select = true
    //     })
    //   }else if(val.value == '全部' && item == false) {
    //     this.inasList.map( e => {
    //       e.select = false
    //     }) 
    //   }
    //   let arr = this.inasList.slice(1,99999)
    //   this.inasList[0].select = arr.every( e => e.select == true)
    // },
    // 获取数勾选值
    getsavelist(arr,list){
      list.map( e => {
        if(e.checked){
          arr.push(e.value)
        }
        if(e.children){
          this.getsavelist(arr,e.children)
        }
      })
    },
    // 保存授权系统
    saveInas(){
      let savelist = []
      this.getsavelist(savelist,this.inasList)
      this.$post('/auth/api/auth/pc/tenantSystem/authorizationSystem',{
        systemIds:savelist.join(','),
        tenantId:this.$route.query.tenantId
      }).then(res => {
        if(res.code == 200){
          this.$Message.success({
            content: '授权成功',
            background: true
          })
          this.inasStatus = false
          
          this.getList(this.$route.query.tenantId)
          
        }else {
          this.$Message.error({
            content: '授权失败',
            background: true
          })
        }
      })
      
    },


    // 授权菜单框
    changeMenuStatus(val){
      this.menuStatus = val
    },
    // 打开授权菜单面板-获取菜单
    openMenuTree(data){
      this.menuStatus = true
      this.tenantSystemId = data.tenantSystemId
      this.getTenantSystemModuleList(data)
      // this.getMenuTree(data.systemId)
      // this.$get('/auth/api/auth/pc/systemModule/selectAllMenuTree',{
      //   systemId:data.systemId
      // }).then(res => {
      //   if(res.code == 200){
      //     this.menuTree = res.dataList
      //     this.handleMenuTree(this.menuTree)
      //   }
      // })
    },
    // 获取菜单
    getMenuTree(systemId){
      this.$get('/auth/api/auth/pc/systemModule/selectAllMenuTree',{
        systemId,
      }).then(res => {
        if(res.code == 200){
          this.menuTree = res.dataList
          this.handleMenuTree(this.menuTree)
        }else {
          this.$Message.error({
            content: res.desc,
            background: true
          })
        }
      })
    },
    handleMenuTree(list){
      list.forEach( (e,i) => {
        list[i] = {
          title : e.name,
          expand : true,
          children: e.child,
          value:e.sysModuleId,
        }
        if(this.tenantSystemModuleList.includes(e.sysModuleId)){
          list[i].checked = true
        }else {
          list[i].checked = false
        }
        if (list[i].children) {
          this.handleMenuTree(list[i].children);
        } else {
          delete(list[i]['expand'])
          delete(list[i]['children'])
        }
      } )
    },

    // 保存授权菜单
    saveMenu(){
      let savelist = []
      this.getsavelist(savelist,this.menuTree)
      this.$post('/auth/api/auth/pc/tenantSystem/authorizationMenu',{
        menuIds:savelist.join(','),
        tenantSystemId:this.tenantSystemId
      }).then( res => {
        if(res.code == 200){
          this.$Message.success({
            content: '授权成功',
            background: true
          })
          this.menuStatus = false
        }else {
          this.$Message.error({
            content: '授权失败',
            background: true
          })
        }
      })
    },  

    // 获取已经授权的菜单
    getTenantSystemModuleList(data){
      this.$get('/auth/api/auth/pc/tenantSystem/getTenantSystemModuleList',{
        tenantSystemId:data.tenantSystemId
      }).then( res => {
        if(res.code == 200){
          this.tenantSystemModuleList = res.dataList.map( e => e.sysModuleId)
          this.getMenuTree(data.systemId)
        }else {
          this.$Message.error({
            content: res.desc,
            background: true
          })
        }
      })
    },




    // 联级
    loadMenuData(item,callback){
      this.$get("/auth/api/auth/pc/systemModule/cascade", {
          parentId: item.sysModuleId,
          systemId:'13' || this.$route.query.systemId
      }).then( res => {
        if( res.code == 200){
          let arr = res.dataList.map( e => {
            if(e.hasChild == 2){
              return {
                title:e.name,
                sysModuleId:e.sysModuleId,
                checked:false,
                children:[],
                loading: false,
              }
            }else{
              return {
                title:e.name,
                sysModuleId:e.sysModuleId,
                checked:false,
              }
            }
          })
          item.children = arr;
          callback(item.children);
        }
      })
    },
    
  }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}

// /deep/.ivu-modal-close {
//   display: none;
// }
.ivu-checkbox-wrapper{
  width: 48%;
  &:first-child{
    width: 100%;
  }
}
</style>